/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getRouteName} from 'containers/App/AppState';
import {isUserReadOnly, isUserReadOnlyAll, doesUserHaveGlobalObjectPermissions} from 'containers/User/UserState';
import {getLoadBalancersMap, getLoadBalancerInstance} from 'containers/LoadBalancer/Item/LoadBalancerItemState';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {gridSettings, categories} from './VirtualServerListConfig';
import {isOnLoadBalancerPage, getFriendlyMode} from '../VirtualServerUtils';
import {getAllResourcesObject} from 'containers/Selector/SelectorUtils';
import {getMappedLabelsForGrid} from 'components/Grid/GridUtils';

export default {
  dvs(state = [], action) {
    switch (action.type) {
      case 'DISCOVERED_VIRTUAL_SERVERS':
        return action.data;
      default:
        return state;
    }
  },

  count(state = {}, action) {
    switch (action.type) {
      case 'VIRTUAL_SERVERS_GET_LIST':
        return action.data.count;
      default:
        return state;
    }
  },
};

export const getDiscoveredVirtualServers = state => state.virtualservers.dvs;
export const getVirtualServersCount = state => state.virtualservers.count;

const getVirtualServersRows = createSelector(
  [
    getDiscoveredVirtualServers,
    isOnLoadBalancerPage,
    getLoadBalancersMap,
    getLoadBalancerInstance,
    isUserReadOnly,
    doesUserHaveGlobalObjectPermissions,
  ],
  (
    virtualServers,
    isOnLoadBalancerPage,
    loadBalancersMap,
    currentSLB,
    userIsReadOnly,
    userHasGlobalObjectPermissions,
  ) =>
    virtualServers
      .filter(object => {
        if (isOnLoadBalancerPage) {
          return object.slb.href === currentSLB.href;
        }

        return object.virtual_server !== null;
      })
      .map(dvs => {
        const item = dvs.virtual_server;
        const {vip_port: vipPort, mode: dvsMode, name, href: vshref} = dvs;

        return {
          key: item?.href || dvs.href,
          // Virtual Server is in draft mode if it has pending status
          draft: Boolean(item?.update_type),
          selectable: userHasGlobalObjectPermissions,
          // Virtual Server is removable only if it's active or with pending changes expect pending deletion
          writable: !userIsReadOnly && (!item?.update_type || item?.update_type !== 'delete'),
          // Fill each IPList with user object
          data: {
            ...item,
            managed: getFriendlyMode(item?.mode),
            vshref,
            name,
            vipPort,
            dvsMode,
            slb: loadBalancersMap.get(dvs.slb.href),
            labelsMap: getMappedLabelsForGrid(item?.labels),
          },
        };
      }),
);

export const getGridSettings = createSelector(
  [isUserReadOnlyAll, isOnLoadBalancerPage, gridSettings],
  (userIsReadOnlyAll, isOnLoadBalancerPage, gridSettings) => {
    const columns = {...gridSettings.columns};

    columns.slb.disabled = isOnLoadBalancerPage;
    columns.checkboxes.disabled = userIsReadOnlyAll;

    return {...gridSettings, columns};
  },
);

export const getFilterMap = createSelector([categories], categories => getAllResourcesObject(categories));

const getGrid = state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getVirtualServersRows,
    filterMap: getFilterMap,
  });

export const getVirtualServersPage = createSelector(
  [getGrid, getVirtualServersCount, getDiscoveredVirtualServers, getRouteName, categories],
  (grid, count, dvsList, routeName, categories) => ({grid, count, dvsList, routeName, categories}),
);
