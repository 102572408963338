/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from '../FormUtils';
import RadioGroup from './RadioGroup';

FormRadioGroup.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,

  // Children can be either a function, that will get current name/values/form as arguments
  // and that is called on render to return any further renderable content that contains FormRadio,
  // or renderable content can be provided directly
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // By default state of group and values are automatically handled by FormRadio itself (uncontrolled).
  // If you want to intercept change event and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // By default radios are swrapped into a div with .gapLarge class.
  // Set noWrap to true to return radios as is, without any wrapper or styling
  noWrap: PropTypes.bool,
  // Or you can specify other gap classes or any other classes by simply passing className prop
  // Like <Form.RadioGroup className={`${styles.gapSmall} ${styles.gapAlbatross}`}> for smaller gaps in albatross line
  className: PropTypes.string,

  // Might depend on other fields
  ...whenPropTypes,
};

export function FormRadioGroup(props) {
  // Control field reset when dependecies are not true
  const {
    [forwardRefSymbol]: ref,
    onChange,
    form,
    field,
    children,
    childrenFunction,
    ...radioGroupProps
  } = useWhen(props.form, props.field, props);
  const {name, value} = field;

  radioGroupProps.ref = ref;
  radioGroupProps.value = value;
  radioGroupProps.name = name;
  radioGroupProps.onChange = (evt, radioProps) => {
    const {value: newValue} = radioProps;

    if (value === newValue) {
      return;
    }

    if (onChange) {
      // If group is controlled, just call onChange prop and let parent decide what to do
      return onChange(evt, name, newValue);
    }

    const initialValue = form.initialValues[name];

    if (newValue === initialValue) {
      // If list of selected radios is equal to default one,
      // then revert to default one to keep object reference, so formik can unmark it as changed
      form.setFieldValue(name, initialValue);
    } else {
      // Set the new value and mark it as touched
      form.setFieldValue(name, newValue);
      form.setFieldTouched(name, true);
    }
  };

  return <RadioGroup {...radioGroupProps}>{children || childrenFunction}</RadioGroup>;
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(
  ({children, ...props}) => {
    if (typeof children === 'function') {
      props.childrenFunction = children;
      children = null;
    }

    return (
      <Field {...props} component={FormRadioGroup}>
        {children}
      </Field>
    );
  },
  {hoistSource: FormRadioGroup},
);
