/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getAppGroupLabelTypes} from 'containers/App/AppState';
import {getAppGroups, getAppGroupLabels} from 'containers/AppGroups/AppGroupState';
import {getReportTemplates} from '../../ReportingTemplateState';
import {createAppGroupName, createAppGroupLabelsQuery} from '../../ReportingUtils';
import {getLatestUniquelyLabelledQueries, transformQueries} from './RecurrenceEditUtils';
import {getUserSettings} from 'containers/User/Settings/SettingsState';
import {mapQueryNamePrefix} from 'containers/IlluminationMap/Filter/MapFilterConstants';
import {getAllServices} from 'containers/Service/List/ServiceListState';

export default {
  recentFilters(state = [], action) {
    switch (action.type) {
      case 'RECENT_FILTER_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },

  savedFilters(state = [], action) {
    switch (action.type) {
      case 'FAVORITE_FILTER_GET_LIST':
        return action.data;
      default:
        return state;
    }
  },
};

const MAX_ENDPOINTS = 1000;
const MAX_RECENT_FILTERS = 10;

export const getRecentFilters = state => state.reporting.recentFilters;
export const getSavedFilters = state => state.reporting.savedFilters;

const getFiltersList = createSelector([getRecentFilters, getSavedFilters], (recent, favorites) => ({
  favorites: favorites || [],
  recent: (recent || []).map(query => {
    return {
      ...query,
      queryName: (query.queryName ?? query.label ?? '').replace(new RegExp(`^${mapQueryNamePrefix}`), ''),
    };
  }),
}));

export const getAppGroupOptions = createSelector(
  [getAppGroups, getAppGroupLabels, getAppGroupLabelTypes],
  (appGroups, labels, labelTypes) => {
    return (appGroups || []).reduce(
      (result, appGroup) => {
        if (
          appGroup.num_workloads +
            appGroup.num_virtual_services +
            appGroup.num_container_workloads +
            appGroup.num_virtual_servers <
          MAX_ENDPOINTS
        ) {
          const name = createAppGroupName(appGroup.label_ids, labels, labelTypes);
          const labelsQuery = createAppGroupLabelsQuery(appGroup.label_ids, labels);

          result.appGroupMap[name] = appGroup.href;
          result.appGroupStatics.push(name);
          result.appGroupData[appGroup.href] = {
            ...appGroup,
            name,
            labelsQuery,
            labels: labelsQuery,
          };
        }

        return result;
      },
      {appGroupMap: {}, appGroupStatics: [], appGroupData: {}},
    );
  },
);

export const getRecurrenceEditPage = createSelector(
  [getAppGroupOptions, getReportTemplates, getFiltersList, getUserSettings, getAllServices],
  (appGroupOptions, templates, filters, userSettings, services) => {
    const appGroups = Object.values(appGroupOptions.appGroupData);

    return {
      templates,
      appGroupOptions,
      filterOptions: {
        favorites: transformQueries({queries: filters.favorites, appGroups, userSettings, services}),
        recent: transformQueries({
          queries: getLatestUniquelyLabelledQueries(filters.recent, MAX_RECENT_FILTERS),
          appGroups,
          userSettings,
          services,
        }),
      },
    };
  },
);
