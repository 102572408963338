/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createElement} from 'react';
import * as PropTypes from 'prop-types';
import {Field} from 'formik';
import {forwardRefFactory, forwardRefSymbol} from 'react-forwardref-utils';
import {whenPropTypes, useWhen} from './FormUtils';
import * as Selector from 'containers/Selectors';

FormSelectorOld.propTypes = {
  // Name that corresponds to your form's schema
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  // By default FormSelectorOld calls Selector onSelectionChange method and set selection changes to form values
  // If you want to intercept selection change and handle it manually, pass onChange handler to make it controlled by parent component
  onChange: PropTypes.func,

  // Might depend on other fields
  ...whenPropTypes,
};

export function FormSelectorOld(props) {
  // Control field reset when dependecies are not true
  const {
    [forwardRefSymbol]: ref,
    onChange,
    form,
    field,
    type,
    ...selectorProps
  } = useWhen(props.form, props.field, props);
  const {name, value} = field;

  // An error border will be displayed by default if the name for this input appears as a key in the errors object.
  const error = _.get(form.touched, name) === true && _.has(form.errors, name);
  // An error message will be displayed if the value for this inputs key is not undefined.
  const errorMessage = error && _.get(form.errors, name);

  if (errorMessage) {
    selectorProps.errorMessage = errorMessage;
  }

  selectorProps.ref = ref;
  selectorProps.initialItems = Array.isArray(value) ? [...value] : [];
  selectorProps.onFocus = () => {
    // Only call touched when hasn't been touched
    // User can click on selector but not make selection, thus set touched
    if (!form.touched[name]) {
      form.setFieldTouched(name, true);
    }
  };

  selectorProps.onSelectionChange = selection => {
    if (onChange) {
      // If group is controlled, just call onChange prop and let parent decide what to do
      // e.g. selection = {
      //              categoryKey: "labels"
      //              categoryName: "Labels"
      //              href: "/orgs/1/labels/25"
      //              key: "app"
      //              value: "coolios"}
      return onChange(selection, name);
    }

    const initialValue = _.get(form.initialValues, name);

    if (
      selection &&
      initialValue &&
      selection.length === initialValue.length &&
      selection.every(selectedItem =>
        initialValue.find(
          ({value, href}) => (value && value === selectedItem.value) || (href && href === selectedItem.href),
        ),
      )
    ) {
      // If the selected options are equal to the default ones,
      // then revert to the default to keep object references, so formik can unmark it as unchanged
      form.setFieldValue(name, initialValue);
    } else {
      // Set the new value and mark it as touched
      form.setFieldValue(name, selection);
      form.setFieldTouched(name, true);
    }
  };

  return createElement(Selector[type], selectorProps);
}

// Temporary Field wrapper until we get hooks in Formik 2.0
export default forwardRefFactory(props => <Field {...props} component={FormSelectorOld} />, {
  hoistSource: FormSelectorOld,
});
