/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import * as PropTypes from 'prop-types';
import {PureComponent} from 'react';
import {TypedMessages} from 'components';
import {SingleGroupMultiItemSelect} from 'containers/Selectors';

export default class FormSingleGroupMultiItemSelect extends PureComponent {
  static propTypes = {
    initialItems: PropTypes.array,
    facets: PropTypes.object,
    statics: PropTypes.object,
    categories: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    objects: PropTypes.array.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.bool,
    size: PropTypes.string, // size of the parent container e.g. FormContainerElement
    onChange: PropTypes.func,
  };

  static defaultProps = {
    showErrorMessage: true,
    size: 'normal',
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  handleSelectionChange(selection) {
    const {setFieldValue, name} = this.props;
    const hrefs = selection && selection.map(item => item.href);

    setFieldValue(name, hrefs);

    if (this.props.onChange) {
      this.props.onChange(selection);
    }
  }

  render() {
    const {errors, touched, name, showErrorMessage, disabled} = this.props;

    const selectorError = Boolean(touched[name] && errors[name]);

    return (
      <div>
        <SingleGroupMultiItemSelect
          initialItems={this.props.initialItems}
          placeholder={this.props.placeholder}
          objects={[{type: 'workloads'}]}
          facets={this.props.facets}
          statics={this.props.statics}
          onSelectionChange={this.handleSelectionChange}
          categories={this.props.categories}
          disabled={disabled}
        />
        <TypedMessages key="status" gap="gapXSmall">
          {[
            showErrorMessage && selectorError ? {content: errors[name], color: 'error', fontSize: 'var(--12px)'} : null,
          ]}
        </TypedMessages>
      </div>
    );
  }
}
